import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Content from './Content'

const useStyles = makeStyles(() => ({
    completeMsg : {
        whiteSpace : 'pre-wrap',

        '& .title' : {
            fontSize : 28,
            fontWeight : 'bold',
            marginTop : 20,
            marginBottom : 10,
        },
    },
}))

const Complete = ({setting, logo}) => {
    const classes = useStyles()

    return (
        <Content
            setting={setting}
            logo={logo}
        >
            {setting.completeMsg ?
            <div className={classes.completeMsg} dangerouslySetInnerHTML={{__html : setting.completeMsg}}/>
            :
            <div className={classes.completeMsg}>
                <div className="title">
                    <Typography variant="h5">{setting.dict.messages.問合せ完了タイトル}</Typography>
                </div>
                <div className="text">
                    {setting.dict.messages.問合せ完了テキスト}
                </div>
            </div>
            }
        </Content>
    )
}

export default Complete
