import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import ErrorBoundary from './ErrorBoundary'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import conf from "./config/config.js"

const regWebDomain = new RegExp(`^https:\\/\\/${conf.torayWebDomain.replace(/\./g,"\\.")}.*`)
const icon = regWebDomain.test(window.location.href) ? `https://${conf.torayWebDomain}/favicon/favicon.ico` : "data:,"
ReactDOM.render(
    <>
        <React.StrictMode>
            <HelmetProvider>
                <ErrorBoundary>
                    <Helmet>
                        <link rel="icon" href={icon}/>
                    </Helmet>
                    <App />
                </ErrorBoundary>
            </HelmetProvider>
        </React.StrictMode>
    </>,
    document.getElementById('root')
)

