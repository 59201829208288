import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import Box from '@material-ui/core/Box'
import constants from  '../Constants'

/*
 * その他の選択肢があるカスタム項目作成コンポーネント
 */
const OtherChoice = ({field, customItem, changeCustomForm, changeValidateMsg}) => {

    // 選択肢最後の値
    const o = Object.keys(field.options)
    const last = o.find(r => Number(field.options[r].index) === o.length - 1)

    const handleChange = (event, field, index) => {
        // エラーメッセージのリセット
        changeValidateMsg({[field.code] : ''})

        // index が0は選択肢
        // 1はその他のテキストフィールドの入力値
        const value = customItem
        if (!index) {
            if (field.type === 'CHECK_BOX') {
                value[0] = event.target.checked ? [...value[0], event.target.value] : value[0].filter(v => v !== event.target.value)
                if (!value[0].some(r => r === last) && value[1] !== '') {
                    value[1] = ''
                }
                value[0].sort(compareValue)
            } else {
                value[0] = event.target.value
                if (last !== event.target.value && value[1] !== '') {
                    value[1] = ''
                }
            }
        } else {
            value[1] = event.target.value
        }
        changeCustomForm({[field.code] : value})
    }

    const compare = (a, b) => a.index - b.index

    const compareValue = (a, b) => 
       (Object.values(field.options).find(c => c.label === a).index) - (Object.values(field.options).find(c => c.label === b).index)

    const hideTransferValue = (options) => {
        delete options[constants.transferSelect]
        return options
    }

    return (
        <div>
        {field.type === 'RADIO_BUTTON' &&
            <Box>
                <RadioGroup
                    name={field.code}
                    row={field.align === 'HORIZONTAL'}
                    value={customItem[0]}
                    onChange={e => handleChange(e, field, 0)}
                >
                    {Object.values(hideTransferValue(field.options)).sort(compare).map((o, i) =>
                        <FormControlLabel
                            key={i}
                            value={o.label}
                            label={o.label}
                            control={<Radio color="primary" />}
                        />
                        )}
                </RadioGroup>
                <TextField
                    className="withLabel"
                    InputLabelProps={{shrink : true}}
                    label={last}
                    size="small"
                    disabled={customItem[0] !== last}
                    value={customItem[1]}
                    onChange={e => handleChange(e, field, 1)}
                />
            </Box>
        }
        {field.type === 'CHECK_BOX' &&
            <Box>
                <FormGroup
                    row={field.align === 'HORIZONTAL'}
                    value={customItem[0]}
                    onChange={e => handleChange(e, field, 0)}
                >
                    {Object.values(hideTransferValue(field.options)).sort(compare).map((o, i) =>
                        <FormControlLabel
                            key={i}
                            label={o.label}
                            control={<Checkbox
                                color="primary" 
                                value={o.label}
                                checked={customItem[0].includes(o.label)}
                            />}
                        >
                        </FormControlLabel>
                    )}
                </FormGroup>
                <TextField
                    className="withLabel"
                    InputLabelProps={{shrink : true}}
                    label={last}
                    size="small"
                    value={customItem[1]}
                    onChange={e => handleChange(e, field, 1)}
                    disabled={!customItem[0].some(r => r === last)}
                />
            </Box>
        }
        </div>
    )
}

export default OtherChoice
