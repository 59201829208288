import { useState, useEffect, useCallback } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { ThemeProvider } from '@material-ui/core/styles'
import Input from './Input'
import Confirm from './Confirm'
import Complete from './Complete'
import Err from './Err'
import inquiryTheme from './inquiryTheme'

const  App = () => {
    const [app, setApp] = useState(undefined)
    const [site, setSite] = useState(undefined)
    const [setting, setSetting] = useState({})
    const [fields, setFields] = useState(undefined)
    const [form, setForm] = useState(undefined)
    const [customForm, setCustomForm] = useState(undefined)
    const [err, setErr] = useState(false)
    const [logo, setLogo] = useState({})
    const [consent, setConsent] = useState({})
    const [agree, setAgree] = useState(false)
    const [inquiryFiles, setInquiryFiles] = useState({})
    const [levelItems, setLevelItems] = useState({})
    const handleApp = useCallback(
        a => setApp(a),
        [],
    )
    const handleSite = useCallback(
        a => setSite(a),
        [],
    )
    const handleFields = useCallback(
        fields => setFields(fields),
        [],
    )
    const handleForm = useCallback(
        f => setForm(f),
        []
    )
    const handleCustomForm = useCallback(
        f => setCustomForm(f),
        [],
    )
    const handleErr = useCallback(
        e => setErr(e),
        [],
    )
    const handleLogo = useCallback(
        l => setLogo(l),
        [],
    )
    const handleConsent = useCallback(
        c => setConsent(c),
        [],
    )
    const handleLevelItems = useCallback(
        l => setLevelItems(l),
        [],
    )
    const toggleAgree = useCallback(
        () => setAgree(c => !c),
        []
    )
    const changeSetting = useCallback(
        s => setSetting(c => ({...c, ...s})),
        [],
    )
    const changeForm = useCallback(
        f => setForm({...f}),
        [],
    )
    const changeField = useCallback(
        f => setForm(c => ({...c, ...f})),
        [],
    )
    const changeCustomForm = useCallback(
        // f {フィールドコード : value}
        // o フィールドコード ※住所検索の検索結果挿入時のみ
        // v 挿入する検索結果 ※住所検索の検索結果挿入時のみ
        (f, o, v) => {
            if (o && v) {
                // 住所検索項目で、検索結果の値を挿入する場合の処理
                setCustomForm(c => {
                    const value = c[o]
                    value[0] = v || ''
                    return {...c, [o] : value}
                })
            } else {
                setCustomForm(c => ({...c, ...f}))
            }
        }
        ,[]
    )
    const handleInquiryFiles = useCallback(
        (f, field) => {
            setInquiryFiles(c => ({...c, [field] : f}))
        },
        [],
    )

    /**
     * titleの設定
     */
    useEffect(() => {
        if (!setting.dict) return
        document.title = (setting.labelSetting ||{}).ページタイトル || setting.dict.titles.お問い合わせ + ' | TORAY'
    }, [setting.dict, setting.labelSetting])

    /**
     * GTMコードの設定
     */
    useEffect(() => {
        if (!setting.gtmCode) return
        TagManager.initialize({gtmId: setting.gtmCode})
    }, [setting.gtmCode])

    // エラー切り分け用内部コンポーネント
    const ThrowError = () => {
        useEffect(() => {
            const e = new Error('不正なURL')
            e.reason = 'incorrectUrl'
            setErr(e)
        },[])
        return null
    }

    // エラー画面
    if (err) return (<Err err={err} />)

    return (
        <BrowserRouter>
            <ThemeProvider theme={inquiryTheme}>
                <Switch>
                    <Route exact path={['/input/:app']}>
                        <Input
                            handleErr={handleErr}
                            handleApp={handleApp}
                            handleSite={handleSite}
                            setting={setting}
                            changeSetting={changeSetting}
                            logo={logo}
                            handleLogo={handleLogo}
                            consent={consent}
                            handleConsent={handleConsent}
                            agree={agree}
                            setAgree={setAgree}
                            toggleAgree={toggleAgree}
                            fields={fields}
                            handleFields={handleFields}
                            changeField={changeField}
                            form={form}
                            handleForm={handleForm}
                            changeForm={changeForm}
                            inquiryFiles={inquiryFiles}
                            handleInquiryFiles={handleInquiryFiles}
                            customForm={customForm}
                            handleCustomForm={handleCustomForm}
                            changeCustomForm={changeCustomForm}
                            levelItems={levelItems}
                            handleLevelItems={handleLevelItems}
                        />
                    </Route>
                    <Route exact path='/confirm'>
                        <Confirm
                            handleErr={handleErr}
                            app={app}
                            setting={setting}
                            logo={logo}
                            fields={fields}
                            form={form}
                            changeField={changeField}
                            inquiryFiles={inquiryFiles}
                            site={site}
                        />
                    </Route>
                    <Route exact path='/complete'>
                        <Complete
                            setting={setting}
                            logo={logo}
                        />
                    </Route>
                    <Route>
                        <ThrowError/>
                    </Route>
                </Switch>
            </ThemeProvider>
        </BrowserRouter>
    )
}

export default App
