import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const useStyles = makeStyles(theme => ({
    root : {
        marginTop : 10,
        padding : 20,
        backgroundColor : '#eee',
        display : 'flex',
        flexDirection : 'column',
        alignItems : 'center',

        '& > div' : {
            whiteSpace : 'pre-wrap',
        },
        '& .consent-msg1' : {
            fontSize : '0.9rem',
            padding : 10,
        },
        '& .agree' : {
            padding : 10,
        },
        '& .MuiCheckbox-root:not(.Mui-checked)' : {
            color : 'rgba(0, 0, 0, 0.54)',
        },

        [theme.breakpoints.down('sm')] : {
            padding : 10,
            '& .consent-msg1' : {
                fontSize : '0.7rem',
                padding : 4,
            },
        },
    },
}))


const Consent = ({dict, message, agree, toggleAgree, setting}) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className="consent-msg1" dangerouslySetInnerHTML={{ __html : message}} />
            <div className="agree">
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            checked={agree}
                            onChange={toggleAgree}
                        />
                    }
                    label={(setting.labelSetting || {}).個人情報の取扱い本文チェックボックス || dict.labels.同意する}
                />
            </div>
        </div>
    )
}

export default Consent

