import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

const inquiryTheme = createMuiTheme({
    typography : {
        body1 : {
            '@media (max-width:600px)' : {
                fontSize : '0.9rem',
            },
        },
        body2 : {
            '@media (max-width:600px)' : {
                fontSize : '0.7rem',
            },
        },
        h4 : {
            fontWeight : 700,
        },
        h5 : {
            fontWeight : 700,
        },
        h6 : {
            fontWeight : 700,
        },
    },
    components : {
        MuiFormControlLabel : {
            styleOverrides : {
                root : {
                    marginLeft : 0,
                },
            },
        },
        MuiTextField : {
            styleOverrides : {
                root : {
                    width : '100%',
                },
            },
        },
        MuiOutlinedInput : {
            styleOverrides : {
                inputSizeSmall : {
                    padding : '4px 8px !important',
                },
            },
        },
        MuiRadio : {
            styleOverrides : {
                root : {
                    padding : '0 2px 0 0 !important',
                },
            },
        },
        MuiSvgIcon : {
            styleOverrides : {
                root : {
                    width : '0.9em',
                },
            },
        },
        MuiAutocomplete : {
            styleOverrides : {
                popupIndicator : {
                    padding : '2px !important',
                    marginRight : '-2px !important',
                },
                clearIndicator : {
                    padding : '2px !important',
                    marginRight : '-2px !important',
                },
            },
        },
        MuiAlert : {
            styleOverrides : {
                root : {
                    alignItems : 'center',
                    padding : 10,
                    marginBottom : 10,
                },
                message : {
                    padding : 0,
                },
                standardError : {
                    color : 'red',
                    backgroundColor : 'rgb(253, 236, 234)',
                },
            },
        },
        MuiMenuItem : {
            styleOverrides : {
                root : {
                    whiteSpace : 'normal'
                },
            },
        },
    },
})


export default responsiveFontSizes(inquiryTheme)
