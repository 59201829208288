const errBg = '#ffebec'
export const inquiryStyle = theme => {
    return {
        root : {
            display: 'flex',
            alignItems: 'stretch',
            borderBottom : 'solid 1px #ccc',

            '& .MuiSelect-outlined' : {
                padding: '4px 8px !important',
            },
            '& .MuiOutlinedInput-inputMultiline' : {
                padding: '4px 8px !important',
            },
            '& .MuiIconButton-label' : {
                paddingBottom : 2,
            },
            '& .MuiRadio-colorPrimary:not(.Mui-checked)' : {
                color : 'rgba(0, 0, 0, 0.54)',
            },

            '& .label' : {
                flexBasis : '30%',
                padding : 10,
                background : '#eee',
                wordBreak : 'break-word',
            '&.required::after' : {
                content : '" *"',
                color : 'red',
            },
            },
            '& .value' : {
                flexBasis : '70%',
                padding: 6,

                '& .attachedFile' : {
                    width : '200px',
                },
                '& .multiSelect' : {
                    width : '100%',
                    padding: '4px 8px !important',
                },
                '& .err' : {
                    paddingTop : 4,
                    fontSize : '0.8rem',
                    color : 'red',
                },
                '& .explain' : {
                    paddingBottom : 4,
                    fontSize : '0.8rem',
                },
                '& .MuiFormGroup-root' : {
                    display : 'inline-flex',
                    marginTop : 4,
                },
                ' & .PrivateSwitchBase-root' : {
                    padding : 0
                },
                '&.err' : {
                    '& .MuiFormGroup-root' : {
                        background : errBg,
                    },
                    '& .MuiInputBase-root' : {
                        background : errBg,
                    },
                },
            },
            '& .separate' : {
                flexBasis : '49%',
            },
            '& .pcAddress' : {
                '& .MuiTextField-root' : {
                    width : '30% !important',
                },
                '& Button' : {
                    marginLeft : '10px',
                    padding : '4px',
                },
                '& .errForm' : {
                    paddingTop : 0,
        
                    '& .MuiInput-root' : {
                        background : errBg,
                    },
                    '& .MuiFormControl-root' : {
                        background : errBg,
                    },
                    '& .MuiFormGroup-root' : {
                        background : errBg,
                    },
                },
                '& .errMsg' : {
                    paddingTop : 4,
                    fontSize : '0.8rem',
                    color : 'red',
                },
            },
            '& .stAddress' : {
                '& .errForm' : {
                    paddingTop : 0,
        
                    '& .MuiInput-root' : {
                        background : errBg,
                    },
                    '& .MuiFormControl-root' : {
                        background : errBg,
                    },
                    '& .MuiFormGroup-root' : {
                        background : errBg,
                    },
                },
                '& .errMsg' : {
                    paddingTop : 4,
                    fontSize : '0.8rem',
                    color : 'red',
                },
            },
            [theme.breakpoints.down('sm')] : {
                flexDirection : 'column',
                border : 'none',

                '& .label' : {
                    alignSelf : 'stretch',
                    paddingTop : 4,
                    paddingBottom : 2,
                },
                '& .value' : {
                    alignSelf : 'stretch',
                    padding : '4px 0 10px 0',
                }
            }
        }
    }
}
