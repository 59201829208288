import { useState, useEffect, useCallback } from 'react'
import Amplify, { API } from 'aws-amplify'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { inquiryStyle } from './inputStyle'
import Alert from '@material-ui/core/Alert'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import InputItem from './InputItem'
import Consent from './Consent'
import Content from './Content'
import awsconfig from './aws-exports'
import valid from './validate'
import customUse from './custom/customUse'

const useStyles = makeStyles(theme => ({
    root : {
        '& .button-area' : {
            display: 'flex',
            justifyContent: 'center',
            marginTop: 20,
        },
        '& .description' : {
            whiteSpace : 'pre-wrap',
            marginBottom: 14,
        },
        '& .note' : {
            whiteSpace : 'pre-wrap',
            fontSize: '90%',
            color : 'red',
        },
        '& .commonErrMsg' : {
            whiteSpace : 'pre-wrap',
        },
    }
}))

Amplify.configure(awsconfig)
const apiname = awsconfig.aws_cloud_logic_custom[0].name

const Input = ({handleErr, handleApp, handleSite, setting, changeSetting, logo, handleLogo, consent, agree, setAgree, toggleAgree, handleConsent, fields, handleFields, changeField,
    form, handleForm, inquiryFiles, handleInquiryFiles, changeForm, customForm, handleCustomForm, changeCustomForm, levelItems, handleLevelItems}) => {

    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const [validError, setValidError] = useState(false)
    const [validateMsg, setValidateMsg] = useState({})
    const [customValMsg, setCustomValMsg] = useState({})
    const [levelCodes, setLevelCodes] = useState([])
    const [postCode, setPostCode] = useState({})
    const [address, setAddress] = useState({})
    const [selected, setSelected] = useState({})
    const changeValidateMsg = useCallback(
        m => setValidateMsg(c => ({...c, ...m}))
        ,[]
    )
    const changeCustomValMsg = useCallback(
        m => setCustomValMsg(c => ({...c, ...m}))
        ,[]
    )
    const changeSelected = useCallback(
        s => setSelected(c => ({...c, ...s}))
        ,[]
    )
    const changePostCode = useCallback(
        p => setPostCode(c => ({...c, ...p}))
        ,[]
    )
    const changeAddress = useCallback(
        a => setAddress(c => ({...c, ...a}))
        ,[]
    )
    const {app} = useParams()

     /**
      * 問合せ登録に使用する問合せフォームアプリIDとサイト名の設定
      */
    useEffect(() => {
        handleApp(app)
        const s = new URLSearchParams(location.search).get('s') || document.referrer || '-'
        handleSite(s.replace(/https?:\/\//, ''))
    }, [app, location, handleSite, handleApp])

    /**
     * 問合せフォームから情報を取得する
     * 画面構築用の情報取得のため、初期表示時のみ実行
     * かつ、すでに値を持っている場合は再実行しない
     * ※エラー発生時は画面構築不可のためシステムエラー
     */
    useEffect(() => {(async () => {
        if (!setting.lang || form) return
        try {
            const {[app] : appsInfo, resultCode} = await API.post(apiname, '/getAppsInfo', {body : {app : [app]}})
                .catch(e => {throw new Error(e)})

            if (resultCode === '04') {
                const err = new Error(`該当アプリなし NG`)
                err.reason = 'incorrectUrl'
                throw err
            }
            if (resultCode !== '00') throw new Error(`アプリ情報取得API NG (${resultCode})`)

            const f = createFields(appsInfo, setting.lang)
            handleFields(f)
            const form = Object.keys(f).reduce((o, c) => {
                // formの初期値にはkintoneのデフォルト値を入れておく（文字列 || 配列）
                // ファイルにはデフォルト値を入れないので明示的に空配列を返却しておく
                if ('FILE' === f[c].type) {
                    return {...o, [c] : []}
                } else {
                    return {...o, [c] : f[c].defaultValue}
                }
            }, {})
            handleForm(form)

            // カスタムフィールド項目用のform作成
            const customForm = Object.keys(f).reduce((o, c) => {
                if (['client', 'email', 'stAddress'].includes(f[c].customType)) {
                    return {...o, [c] : ['', '']}
                }
                if (f[c].customType === 'otherChoice') {
                    return {...o, [c] : [f[c].defaultValue, '']}
                }
                if (f[c].customType) {
                    return {...o, [c] : f[c].defaultValue}
                }
                return {...o}
            }, {})
            handleCustomForm(customForm)

            // 階層項目取得用のフィールド一覧の設定
            const levelCodes = Object.values(f).filter(r => r.customType === 'levelItems')
                .map(r => r.code.replace(/_level[1-3]$/, ''))
            setLevelCodes(Array.from(new Set(levelCodes)))

        }
        catch (e) {
            handleErr(e)
        }
    })()}, [app, form, handleFields, handleForm, handleCustomForm, handleErr, setting.lang])

    /**
     * 問合せ管理から取得した情報をもとに、全画面で必要な共通項目を設定
     * システム管理から多言語辞書を取得、問合せ管理の言語に合わせて辞書を加工
     * ロゴ画像の取得
     * 取得済みの場合は、再取得しない
     */
    useEffect(() => {(async () => {
        if (setting.lang) return
        try {
            const {record : manage, resultCode} = await API.post(apiname, '/getContactManage', {body : {app : app}})
                .catch(e => {throw new Error(e)})

            if (resultCode === '04') {
                const err = new Error(`該当アプリなし NG`)
                err.reason = 'incorrectUrl'
                throw err
            }
            if (resultCode !== '00') throw new Error(`問合せ管理取得API NG (${resultCode})`)

            const company = manage[0].言語依存情報.reduce((o, r) => ({...o, [r.value.言語.value] : r}), {})

            // システム管理から多言語辞書を取得
            const {record : sys} = await API.post(apiname, '/getSystemManage', {response : false})

            const labelSetting = manage[0].問合せ画面ラベル個別設定.reduce((o, r) => ({...o, [r.value.ラベル設定箇所.value] : r.value.ラベル.value}), {})
            const errSetting = manage[0].エラーメッセージ個別設定.reduce((o, r) => ({...o, [r.value.エラー設定箇所.value] : r.value.エラーメッセージ.value}), {})
            const setting = {
                lang : manage[0].言語,
                title : manage[0].フォームタイトル,
                copyright : manage[0].Copyright,
                gtmCode : manage[0].GTMコード,
                formClass : manage[0].フォーム区分,
                docReqFile : manage[0].資料請求ファイル,
                labelSetting : labelSetting,
                errSetting : errSetting,
                descriptionMsg : manage[0].フォーム説明文_リッチ.replace(/<\/?[^>]+?>/g, '').length > 0 ? manage[0].フォーム説明文_リッチ : '',
                completeMsg : manage[0].登録後文言.replace(/<\/?[^>]+?>/g, '').length > 0 ? manage[0].登録後文言 : '',
                dict : convertDictionary(sys.多言語辞書, manage[0].言語),
            }
            changeSetting(setting)

            // 個人情報の取り扱いを表示しない場合は、「入力内容の確認へ」ボタンは常に活性にする
            const isShow = manage[0].個人情報の取り扱い表示有無 === '表示あり'
            if (!isShow) setAgree(true)

            const langDep = company[manage[0].言語].value
            const uniqueConsent = manage[0].個人情報の取り扱い本文.replace(/<\/?[^>]+?>/g, '').length > 0 ? manage[0].個人情報の取り扱い本文 : ''
            handleConsent({isShow : isShow, message : uniqueConsent || langDep.個人情報の取り扱い本文.value})

            // ロゴ画像の取得
            const logoInfo = [
                {
                    fileKey : (manage[0].ヘッダーロゴ[0] || {}).fileKey || langDep.ヘッダーロゴ.value[0].fileKey,
                    contentType : (manage[0].ヘッダーロゴ[0] || {}).contentType || langDep.ヘッダーロゴ.value[0].contentType,
                },
                {
                    fileKey : (manage[0].フッターロゴ[0] || {}).fileKey || langDep.フッターロゴ.value[0].fileKey,
                    contentType : (manage[0].フッターロゴ[0] || {}).contentType || langDep.フッターロゴ.value[0].contentType,
                },
            ]

            try {
                // ダウンロードAPIの結果JSON { data : base64_encoded_string }
                const [header, footer] = await Promise.all(
                    logoInfo.map(o => API.post(apiname, '/downloadFile', {body : {file : o.fileKey}}))
                )
                // MIMEは、kintoneのファイル項目のcontentTypeを使用
                handleLogo({
                    header : window.URL.createObjectURL(base64toBlob(header.data, logoInfo[0].contentType)),
                    footer : window.URL.createObjectURL(base64toBlob(footer.data, logoInfo[1].contentType)),
                })
            } catch (e) {
            }
        }
        catch (e) {
            handleErr(e)
        }
    })()}, [app, changeSetting, handleConsent, handleErr, setAgree, setting.lang, handleLogo])

    const base64toBlob = (b, t) => {
        const bin = atob(b)
        const buff = new Uint8Array(bin.length)
        for (let i=0; i < bin.length; i++) {
            buff[i] = bin.charCodeAt(i)
        }
        return new Blob([buff.buffer], {type : t})
    }

     /**
      * 添付ファイル、カスタム項目で設定した内容をformに詰め替える
      * ※添付ファイルは登録処理時に再度詰め替えるが、バリデーションでエラーにならないようにファイル名を設定する
      */
    const refillForm = () => {
        Object.values(fields).forEach(f => {
            if (f.customType === 'client') {
                form[f.code] = customForm[f.code].some(r => r) && customForm[f.code].join(' ')
            } else if (f.customType === 'email') {
                form[f.code] = customForm[f.code][0]
            } else if (f.customType === 'stAddress') {
                form[f.code] = (customForm[f.code] || []).join('')
            } else if (f.customType === 'otherChoice') {
                form[f.code] = customForm[f.code][0]
                form[f.code + '_other'] = customForm[f.code][1]
            } else if (f.customType) {
                form[f.code] = customForm[f.code]
            } else if (f.type === 'FILE') {
                form[f.code] = (inquiryFiles[f.code][0] || {}).name
            }
        })
        changeForm(form)
    }

    const validate = () => {
        let customErr = {}
        Object.values(fields).forEach(f => {
            let errMsg = valid(f, form[f.code], setting.dict.messages, setting.errSetting)

            // カスタム項目の郵便番号が入力されていて住所が入力されていない場合を許容しない
            if (f.customType === 'pcAddress') {
                const stCode = f.code.replace(/_pc_address$/, '') + '_st_address'

                if (customForm[f.code] && !customForm[stCode][0]) {
                    customErr = {
                        ...customErr,
                        [f.code] : (setting.errSetting || {}).郵便番号住所相関エラー || setting.dict.messages.郵便番号住所相関エラー,
                        [stCode] : (setting.errSetting || {}).郵便番号住所相関エラー || setting.dict.messages.郵便番号住所相関エラー
                    }
                }
            }
            if (!errMsg) {
                // カスタム項目の姓名がどちらも入力されているかどうか
                if (f.customType === 'client') {
                    customErr = {
                        ...customErr,
                        [f.code] : customForm[f.code].map(r => {
                            if (!r) {
                                return (setting.errSetting || {}).郵便番号住所相関エラー || setting.dict.messages.必須エラー.replace('{label}', f.label)
                            }
                            return ''
                        })
                    }
                }
                // カスタム項目の2つ入力したメールアドレスが同じ値かどうか
                if (f.customType === 'email') {
                    if (customForm[f.code][0] !== customForm[f.code][1]) {
                        errMsg = (setting.errSetting || {}).メールアドレス照合エラー || setting.dict.messages.メールアドレス照合エラー
                    }
                }
            }
            if (errMsg) {
                validateMsg[f.code] = errMsg
            }
        })
        setValidateMsg(validateMsg)
        setCustomValMsg(customErr)
        return Object.values(validateMsg).every(r => !r) &&
            Object.values(customErr).every(r => Array.isArray(r) ? Object.values(r).every(v => !v) : !r)
    }

    const handleClick = () => {
        refillForm()
        if (validate()) {
            setValidError(false)
            history.push('/confirm')
        }
        else { 
            setValidError(true)
            window.scrollTo(0, 0)
        }
    }

    if (!setting.dict || !fields || !form || !customForm) return null
    return (
        <Content
            setting={setting}
            logo={logo}
        >
            <div className={classes.root}>
                {validError && 
                <Alert severity="error">
                    <div className="commonErrMsg">{(setting.errSetting || {}).入力フォーム共通エラー || setting.dict.messages.入力フォーム共通エラー}</div>
                </Alert>
                }
                <div className="description" dangerouslySetInnerHTML={{ __html : setting.descriptionMsg}}/>
                <div className="note">
                    {(setting.labelSetting || {}).必須入力テキスト || setting.dict.messages.必須入力テキスト}
                </div>
                <div>
                    {Object.keys(fields).map((f) =>
                    <InputItem
                        style={inquiryStyle}
                        dict={setting.dict}
                        labelSetting = {setting.labelSetting}
                        errSetting = {setting.errSetting}
                        key={f}
                        field={fields[f]}
                        form={form[f]}
                        changeField={changeField}
                        validateMsg={validateMsg[f]}
                        changeValidateMsg={changeValidateMsg}
                        customUse={customUse}
                        customValMsg={customValMsg[f]}
                        changeCustomValMsg={changeCustomValMsg}
                        customItem={customForm[f]}
                        changeCustomForm={changeCustomForm}
                        inquiryFiles={inquiryFiles}
                        handleInquiryFiles={handleInquiryFiles}
                        levelCodes={levelCodes}
                        postCode={postCode}
                        changePostCode={changePostCode}
                        address={address}
                        changeAddress={changeAddress}
                        selected={selected}
                        changeSelected={changeSelected}
                        levelItems={levelItems}
                        handleLevelItems={handleLevelItems}
                        record={null}
                        isTel={false}
                    />
                    )}
                </div>
                {consent.isShow &&
                <Consent
                    dict={setting.dict}
                    message={consent.message}
                    agree={agree}
                    toggleAgree={toggleAgree}
                    setting={setting}
                />
                }
                <div className="button-area">
                    <Button
                        variant="contained"
                        color="primary"
                        to="/confirm"
                        disabled={!agree}
                        onClick={handleClick}
                    >
                        <Typography variant="h6">{(setting.labelSetting || {}).入力内容の確認へ || setting.dict.labels.入力内容の確認へ}</Typography>
                    </Button>
                </div>
            </div>
        </Content>
    )
}

// 辞書変換
const convertDictionary = (d, l) => {
    // 表示言語の決定
    let lang = l || (navigator.language || '').split('-')[0]
    lang = d.languages.includes(lang) ? lang : 'en'

    // 画面タイトル／ラベル／メッセージ辞書の変換
    const dict = ['titles', 'labels', 'messages'].reduce((o, k) =>
        ({...o, [k] : Object.keys(d[k]).reduce((o, l) => ({...o, [l] : d[k][l][lang].replace(/\\n/g, '\n')}), {})}), {})

    dict.languages = d.languages
    dict.lang = lang
    return dict
}

/**
 * 入力フォームを表示するためのフィールド設定をkintoneのアプリ設定情報をもとに整形する
 * カスタムする必要のある項目はcustomTypeに該当のカスタム項目名を追加する
 * 
 * カスタム項目名
 *   client
 *   email
 *   levelItems
 *   otherChoice
 *   pcAddress
 *   stAddress
 */
const createFields = (appsInfo, lang) => {
    const { layout } = appsInfo.layout.find(r => r.code === "問合せ情報")
    return layout.reduce((l, r) => {
        const code = r.fields[0].code

        const f = {}
        if (layout.some(r => r.fields[0].code === code + '_other')) {
            f.customType = 'otherChoice'
        } else if (/_pc_address$/.test(code)) {
            const p = code.replace(/_pc_address$/, '')
            if (layout.some(r => r.fields[0].code === p + '_st_address')) {
                f.customType = 'pcAddress'
            }
        } else if (/_st_address$/.test(code)) {
            const p = code.replace(/_st_address$/, '')
            if (layout.some(r => r.fields[0].code === p + '_pc_address')) {
                f.customType = 'stAddress'
            }
        } else if (/_level[1-3]$/.test(code)) {
            f.customType = 'levelItems'
        } else if (code === 'client' && lang !== 'ko') {
            f.customType = 'client'
        } else if (code === 'emailAddress') {
            f.customType = 'email'
        }

        const [ret, label, explain] = appsInfo.properties[code].label.match(/(.+)【(.+)】/) || []
        if (ret) {
            f.label = label
            f.explain = explain
        }

        // 複数フィールドが関連する項目の子要素として表示するため表示しなくなるフィールドかどうか
        if (/_other$/.test(code) && layout.some(r => code.replace(/_other$/, '') ===  r.fields[0].code)) {
            f.customNoDisp = true
        }
        return {...l, [code] : {...appsInfo.properties[code], ...f}}
    }, {})
}

export default Input
