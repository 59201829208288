/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:1cc61cb2-27d0-4c70-8222-8e364da38f8b",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_6EJq5lqFY",
    "aws_user_pools_web_client_id": "1rb3385ja8f6cos9pv5okmr0u0",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "inquirybucket142523-product",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "inquiryapi",
            "endpoint": "https://x2jchy4p5b.execute-api.ap-northeast-1.amazonaws.com/product",
            "region": "ap-northeast-1"
        }
    ],
    "aws_content_delivery_bucket": "torayinquiry-20210610193208-hostingbucket-product",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d1bcwi9geg1krx.cloudfront.net"
};


export default awsmobile;
