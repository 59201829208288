import { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

const torayBlue = 'rgb(0, 64, 152)'
const useStyles = makeStyles(theme => ({
    header : {
        marginTop : 10,
        marginBottom : 20,

        '& .group-logo' : {
            height: 90,
            '& img' : {
                height: '100%',
            },
        },
        '& .title' : {
            color : torayBlue,
            textAlign : 'center',
        },

        [theme.breakpoints.down('sm')] : {
            marginBottom : 3,

            '& .group-logo' : {
                height: 34,
            },
        }
    },
    content : {
        [theme.breakpoints.down('sm')] : {
            fontSize : '0.9rem',
        }
    },
    footer : {
        display : 'flex',
        flexDirection : 'column',
        alignItems : 'center',
        marginTop : 40,
        marginBottom : 20,

        '& img' : {
            height: 60,
        },
        '& img.err' : {
            height: 41,
            marginTop : 8,
            marginBottom : 8,
        },
        [theme.breakpoints.down('sm')] : {
            marginTop : 10,

            '& img' : {
                height : 25,
            },
            '& img.err' : {
                height : 18,
            },
            '& .copyright' : {
                fontSize : 10,
            },
        },
    }
}))

const Content = ({setting, logo, children, err}) => {
    const classes = useStyles()
    const copyright = useMemo(() => setting.copyright.replace(/{YYYY}/g, new Date().getFullYear()), [setting])
    
    return (
        <Container maxWidth="md">
            <div className={classes.header}>
                <div className="group-logo">
                    {logo.header &&
                    <img src={logo.header} alt="logo" />
                    }
                </div>
                <div className="title">
                    <Typography variant="h4">{setting.title}</Typography>
                </div>
            </div>

            <div className={classes.content}>
                {children}
            </div>

            <footer className={classes.footer}>
                {logo.footer &&
                <>
                    <img className={err ? 'err' : ''} src={logo.footer} alt="logo" />
                    <Typography variant="body2" className="copyright">{copyright}</Typography>
                </>
                }
            </footer>
        </Container>
    )
}

export default Content
