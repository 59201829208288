import { useState , useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Amplify, { API, Storage } from 'aws-amplify'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Content from './Content'
import awsconfig from './aws-exports'
import Spinner from './Spinner'

const useStyles = makeStyles(theme => ({
    root : {
        '& .item' : {
            display: 'flex',
            alignItems: 'stretch',
            borderBottom : 'solid 1px #ccc',
        },
        '& .item .label' : {
            padding: 10,
            background: '#eee',
            flexBasis : '30%',
            wordBreak : 'break-word',
        },
        '& .item .value' : {
            padding: 10,
            flexBasis : '70%',
            whiteSpace : 'pre-wrap',
            wordBreak : 'break-word',
        },
        '& .button-area' : {
            display : 'flex',
            justifyContent : 'space-between',
            marginTop: 20,

            '& .left' : {
                flexBasis : '30%',
            },
            '& .center' : {
                flexBasis : '40%',
                textAlign : 'center',
                '& .MuiButton-label' : {
                    fontSize : 20,
                },
            },
            '& .right' : {
                flexBasis : '30%',
            },
        },
        '& .description' : {
            whiteSpace : 'pre-wrap',
            marginBottom: 14,
        },

        [theme.breakpoints.down('sm')] : {
            '& .button-area' : {
                flexDirection : 'column',

                '& .left' : {
                    paddingTop : 10,
                },
                '& .center' : {
                    order : -1,
                }
            },
        }
    }
}))


Amplify.configure(awsconfig)
const apiname = awsconfig.aws_cloud_logic_custom[0].name
 
const Confirm = ({handleErr, app, setting, logo, fields, form, changeField, inquiryFiles, site}) => {
    const [spinnerOpen, setSpinnerOpen] = useState(false)
    const history = useHistory()
    const classes = useStyles()

    /**
     * S3のkeyをformに詰め替える
     */
    useEffect(() => {
        if (!inquiryFiles) return
        const s3keys = Object.keys(inquiryFiles).reduce((o, c) => ({...o, [c] : inquiryFiles[c].reduce((o, r) => ([...o, r.s3key]), [])}), {})
        const file = {}
        Object.keys(s3keys).forEach(c => {
            file[c] = s3keys[c]
        })
        changeField(file)
    }, [inquiryFiles, changeField])

    /**
     * 問合せ内容の登録処理を実施する
     */
    const handleClick = async () => {
        setSpinnerOpen(true)
        try {
            if (inquiryFiles && Object.keys(inquiryFiles).length > 0) {
                // S3にファイルアップロードする
                const file = Object.keys(inquiryFiles).reduce((o, c) => ([...o, ...inquiryFiles[c].reduce((o, r) => ([...o, {s3key : r.s3key, file : r.file}]), [])]), [])
                await Promise.all(file.map(f => Storage.put(f.s3key, f.file)))
            }

            const { resultCode } = await API.post(apiname, '/insertContact',
                {body : {
                    app : app,
                    record : form,
                    サイト : site
                }}
            )
            if (resultCode !== '00') {
                throw new Error()
            }

            const execDownload = async (fileKey, contentType, name) => {
                if (!fileKey || !contentType || !name) return
                const ret = await API.post(apiname, '/downloadFile', {body : {file : fileKey}})
                if (ret.resultCode !== '00') throw new Error(`ファイルダウンロードAPI NG (${ret.resultCode})`)
                const url = URL.createObjectURL(base64toBlob(ret.data, contentType))
                const link = document.createElement("a")
                link.download = name
                link.href =  url
                link.click()
                link.remove()
                URL.revokeObjectURL(url)
            }

            if (setting.formClass === '資料請求' && setting.docReqFile.length) {
                for (const f of setting.docReqFile) {
                    await execDownload(f.fileKey, f.contentType, f.name)
                }
            }

            setSpinnerOpen(false)
            history.push('/complete')
        }
        catch (e) {
            setSpinnerOpen(false)
            handleErr(e)
        }
    }
    
    return (
        <Content
            setting={setting}
            logo={logo}
        >
            <div className={classes.root}>
                <div className="description">
                    {(setting.labelSetting || {}).問合せ確認テキスト || setting.dict.messages.問合せ確認テキスト}
                </div>
                <div>
                    {Object.values(fields).map((f, i) => (
                        <div className="item" key={i}>
                            <div className="label">{f.label}</div>
                            <div className="value">
                                {f.type === 'FILE' ?
                                    inquiryFiles[f.code].reduce((o, r) => ([...o, r.name]), []).join("\n")
                                    :
                                    (Array.isArray(form[f.code]) ? form[f.code].join("\n") : form[f.code])
                                }
                            </div>
                        </div>
                    ))}
                </div>
                <div className="button-area">
                    <div className="left">
                        <Button
                            component={Link}
                            to={`/input/${app}`}
                        >
                            ＜{(setting.labelSetting || {}).入力内容を修正する || setting.dict.labels.入力内容を修正する}
                        </Button>
                    </div>
                    <div className="center">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClick}
                        >
                            {(setting.labelSetting || {}).この内容で送信する || setting.dict.labels.この内容で送信する}
                        </Button>
                    </div>
                    <div className="right"></div>
                </div>
            </div>
            <Spinner spinnerOpen={spinnerOpen}/>
        </Content>
    )
}

const base64toBlob = (b, t) => {
    const bin = atob(b)
    const buff = new Uint8Array(bin.length)
    for (let i=0; i < bin.length; i++) {
        buff[i] = bin.charCodeAt(i)
    }
    return new Blob([buff.buffer], {type : t})
}

export default Confirm
