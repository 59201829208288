import { makeStyles } from '@material-ui/core/styles'
import PcAddress from './custom/PcAddress'
import StAddress from './custom/StAddress'
import Client from './custom/Client'
import EmailAddress from './custom/EmailAddress'
import LevelItems from './custom/LevelItems'
import OtherChoice from './custom/OtherChoice'

const useStyles = makeStyles(theme => ({
    custom : {
        '& .withLabel' : {
            marginTop : '10px',
        },
    }
}))

/*
 * kintoneの項目をフォーム入力項目として生成するカスタム項目用の共通コンポーネント
 *
 *   dict             : 多言語辞書（ラベルのみ）
 *   field            : kintone API で取得した フィールド情報（該当フィールドコードの設定値）
 *   customItem       : カスタム用入力フォーム（レコード）の該当フィールドのObject
 *   changeCustomForm : カスタム用フィールド変更関数
 *   form             : 入力フォーム（レコード）の該当フィールドのObject
 *   changeField      : フィールド変更関数
 *   levelCodes       : 階層項目のフィールド項目
 *   postCode         : 入力された郵便番号
 *   changePostCode   : 郵便番号設定関数
 *   address          : 郵便番号をもとに取得した住所
 *   changeAddress    : 住所設定関数
 *   selected         : 選択中の値の配列
 *   changeSelected   : 選択中の値変更関数
 */
const CustomItems = ({dict, labelSetting, errSetting, field, customItem, changeCustomForm, changeValidateMsg, customValMsg, changeCustomValMsg, levelCodes,
    postCode, changePostCode, address, changeAddress, selected, changeSelected, levelItems, handleLevelItems}) => {

    const classes = useStyles()

    return (
        <div className={classes.custom}>
        {field.customType === 'pcAddress' &&
            <PcAddress
                dict={dict}
                labelSetting={labelSetting}
                errSetting={errSetting}
                field={field}
                customItem={customItem}
                changeCustomForm={changeCustomForm}
                changeValidateMsg={changeValidateMsg}
                customValMsg={customValMsg}
                changeCustomValMsg={changeCustomValMsg}
                changePostCode={changePostCode}
                changeAddress={changeAddress}
            />
        }
        {field.customType === 'stAddress' &&
            <StAddress
                dict={dict}
                labelSetting={labelSetting}
                field={field}
                customItem={customItem}
                changeCustomForm={changeCustomForm}
                changeValidateMsg={changeValidateMsg}
                customValMsg={customValMsg}
                changeCustomValMsg={changeCustomValMsg}
                postCodeValue={postCode[field.code.replace(/_st_address$/, '')]}
                addressValue={address[field.code.replace(/_st_address$/, '')]}
                changeAddress={changeAddress}
                code={field.code.replace(/_st_address$/, '')}
            />
        }
        {field.customType === 'client' &&
            <Client
                dict={dict}
                labelSetting={labelSetting}
                field={field}
                customItem={customItem}
                changeCustomForm={changeCustomForm}
                changeValidateMsg={changeValidateMsg}
                customValMsg={customValMsg}
                changeCustomValMsg={changeCustomValMsg}
            />
        }
        {field.customType === 'email' &&
            <EmailAddress
                dict={dict}
                labelSetting={labelSetting}
                field={field}
                customItem={customItem}
                changeCustomForm={changeCustomForm}
                changeValidateMsg={changeValidateMsg}
            />
        }
        {field.customType === 'levelItems' &&
            <LevelItems
                field={field}
                customItem={customItem}
                changeCustomForm={changeCustomForm}
                changeValidateMsg={changeValidateMsg}
                levelCodes={levelCodes}
                selected={selected}
                changeSelected={changeSelected}
                levelItems={levelItems}
                handleLevelItems={handleLevelItems}
            />
        }
        {field.customType === 'otherChoice' &&
            <OtherChoice
                field={field}
                customItem={customItem}
                changeCustomForm={changeCustomForm}
                changeValidateMsg={changeValidateMsg}
            />
        }
        </div>
    )
}

export default CustomItems
