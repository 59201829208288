import { useState } from 'react'
import Amplify, { API } from 'aws-amplify'
import awsconfig from '../aws-exports'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

Amplify.configure(awsconfig)
const apiname = awsconfig.aws_cloud_logic_custom[0].name

/*
 * 郵便番号のカスタム項目作成コンポーネント
 */
const PcAddress = ({dict, labelSetting, errSetting, field, customItem, changeCustomForm, changeValidateMsg, customValMsg, changeCustomValMsg, changeAddress}) => {

    const [disabled, setDisabled] = useState(false)

    const code = field.code.replace(/_pc_address$/, '')

    const handleChange = (event, field) => {
        // エラーメッセージのリセット
        changeValidateMsg({[field.code] : ''})
        changeCustomValMsg({
            [field.code] : '',
            [code + '_st_address'] : ''
        })

        // 郵便番号が変更されたら、住所はリセットする
        const address = {
            [field.code] : /^.{0,7}$/.test(event.target.value) ? event.target.value : customItem,
            [code + '_st_address'] : ['', ''],
        }
        setDisabled(false)
        changeAddress({[code] : []})
        changeCustomForm(address)
    }

    const handleClick = () => {
        // エラーメッセージのリセット
        changeCustomValMsg({
            [field.code] : '',
            [code + '_st_address'] : ''
        })
        searchAddress()
        setDisabled(true)
    }

    /**
     * 郵便番号検索が行われた場合に実行される
     * 郵便番号に入力された値に紐づく住所一覧の取得
     */
    const searchAddress = async () => {
        try {
            const ret = await API.post(apiname, '/searchAddress', {body : {郵便番号 : customItem}})
                .catch(e => {throw new Error(e)})
                
            const devAddress = {[code] : ret.addressInfo.map(r => {
                const address = r.町域
                return address.reduce((o, v) => (
                    {...o, ...r, 町域名 : v.value.町域名.value}
                ), {})
            }).map(r => (r.都道府県名 || '') + (r.市区町村名 || '') + (r.町域名 || ''))}
            if (!devAddress[code].length) {
                throw new Error()
            }
            changeCustomForm(null, code + '_st_address', devAddress[code])
            changeAddress(devAddress)
        }
        catch (e) {
            changeValidateMsg({[field.code] : (errSetting || {}).郵便番号検索失敗 || dict.messages.郵便番号検索失敗})
            setTimeout(()=>{
                    changeValidateMsg({[field.code] : ''})
            }, 2000)
        }
    }

    return (
        <div className='pcAddress'>
            <div className={((customValMsg || [])[0] ? ' errForm' : '')}>
                <TextField
                    size="small"
                    value={customItem}
                    onChange={e => handleChange(e, field)}
                />
                <Button
                    variant="contained"
                    disabled={!/^\d{7}$/.test(customItem) || disabled}
                    onClick={e => handleClick()}
                >{(labelSetting || {}).検索 || dict.labels.検索}</Button>
            </div>
            {customValMsg &&
                <div className="errMsg">
                    {customValMsg}
                </div>
            }
        </div>
    )
}

export default PcAddress
