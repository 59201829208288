import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Content from './Content'
import errMessage from './errMessage.json'
import headerLogo from './img/TORAY_rogo.svg'
import footerLogo from './img/Toray_Group_rogo.svg'
import conf from "./config/config.js"

const regWebDomain = new RegExp(`^https:\\/\\/${conf.torayWebDomain.replace(/\./g,"\\.")}.*`)
const useStyles = makeStyles(theme => ({
    errMsg : {
        display : 'flex',
        flexDirection : 'column',
        '& .title' : {
            display : 'flex',
            paddingTop : '20px',
            paddingBottom : '4px'
        },
        '& .text' : {
            border: 'solid 1px #ccc',
            whiteSpace : 'pre-wrap',
            padding : '10px',
            height : 'calc(100vh - 340px)',
        },
        '& .button' : {
            marginTop : 10,
            alignSelf : 'center',
        },
        [theme.breakpoints.down('sm')] : {
            '& .text' : {
                height : 'calc(100vh - 200px)',
            },
        },
    },
}))


const Err = ({err}) => {
    const classes = useStyles()

    const lang = (navigator.language || '').split('-')[0]
    const setting = {
        copyright : `Copyright © 2021 TORAY INDUSTRIES, INC.`
    }
    const logo =  {
        header : regWebDomain.test(window.location.href) ? headerLogo : "",
        footer : regWebDomain.test(window.location.href) ? footerLogo : ""
    }
    return (
        <Content
            setting={setting}
            logo={logo}
            err={true}
        >
            <div className={classes.errMsg}>
                <div className="title">
                    <Typography variant="h5">{errMessage.occurMatter[lang]}</Typography>
                </div>
                <div className="text">{err.reason === 'incorrectUrl' ? errMessage.incorrectUrl[lang] : errMessage.networkMatter[lang]}</div>
                <Button
                    className="button"
                    variant="contained"
                    onClick={(e) => {window.close()}}
                >
                    {errMessage.button[lang]}
                </Button>
            </div>
        </Content>
    )
}

export default Err